:root {
  --header-height: 64px;

  --brix-color-icon-font-default: #323232;
  --brix-color-icon-bg-default: transparent;
  --brix-color-icon-bg-hover: #ECF3F7;
  --brix-color-text-default: rgba(0, 0, 0, 0.87);
}
.MuiCardHeader-content {
  overflow: hidden;
}

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}